<template>
  <div id="logistics_trajectory">
    <div v-if="show">
      <div class="search">
        <input type="text" v-model="searchVal" />
        <button class="bt1">查&nbsp;询</button>
        <button class="bt2" @click="scanCode">
          <svg-icon icon-class="rich_scan" />
        </button>
      </div>
      <div class="trajectory">
        <div class="title">
          运输中
          <img src="@/assets/images/logistics.png" alt="" />
        </div>
        <div class="content">
          <div class="kd">
            <a href="https://www.sf-international.com/cn/sc">官网 >></a>
            <img src="@/assets/images/sf.png" alt="" />
            <p>顺丰快递 SF8098483083120</p>
            <i>|</i>
            <span><svg-icon icon-class="phone2" />打电话</span>
          </div>
          <van-steps
            direction="vertical"
            :active="0"
            active-color="#333"
            inactive-color="#888"
            :active-icon="require('@/assets/images/5.png')"
          >
            <van-step>
              <h3>已签收</h3>
              <p>
                【深圳市】正常签收，签收人类型：其他，如有疑问请联系：<span>16626314320</span>。如您未收到此快递，请拨打投诉电话：<span>0551-62392929</span>！客观，您的包裹已安全送达，戳开上方快递员评价，点亮五颗小星星，可在顺丰快递微信公众号或小程序上参与“收货点好评，兑换5元优惠券”活动，感谢使用顺丰速运，期待再次为您服务！
              </p>
              <p>2022-4-19 12:45:53</p>
            </van-step>
            <van-step>
              <template #inactive-icon>
                <img src="@/assets/images/4.png" alt="" />
              </template>
              <h3>派送中</h3>
              <p>
                【深圳市】福田区平安分布营业部派件员：练晓君
                电话：16626314320当前正在为您派件
              </p>
              <p>2022-4-19 11:16:24</p>
            </van-step>
            <van-step>
              <template #inactive-icon>
                <img src="@/assets/images/3.png" alt="" />
              </template>
              <h3>运输中</h3>
              <p>【深圳市】快件已到达 广东深圳公司</p>
              <p>2022-4-19 10:32:01</p>
            </van-step>
            <van-step>
              <p>【广州市】快件已从广州市物流中心发出，正在发 往广东深圳公司</p>
              <p>2022-4-18 17:30:01</p>
            </van-step>
            <van-step>
              <p>【广州市】快件已到达 广州物流中心</p>
              <p>2022-4-18 17:30:01</p>
            </van-step>
            <van-step>
              <p>
                【泉州市】快件已从泉州市物流集散地发出，正在 发往广州物流中心
              </p>
              <p>2022-4-18 15:30:01</p>
            </van-step>
            <van-step>
              <p>【泉州市】快件已到达 泉州物流集散地</p>
              <p>2022-4-18 15:30:14</p>
            </van-step>
            <van-step>
              <p>
                【武夷山】快件已从福建武夷山正山堂茶叶基地发
                出，正在发往泉州物流集散地
              </p>
              <p>2022-4-18 14:30:14</p>
            </van-step>
            <van-step>
              <template #inactive-icon>
                <img src="@/assets/images/2.png" alt="" />
              </template>
              <h3>已发货</h3>
              <p>包裹正在等待揽收</p>
              <p>2022-4-27 14:30:14</p>
            </van-step>
            <van-step>
              <template #inactive-icon>
                <img src="@/assets/images/1.png" alt="" />
              </template>
              <h3>仓库处理中</h3>
              <p>您的订单开始处理</p>
              <p>2022-4-18 12:52:54</p>
            </van-step>
            <van-step>
              <template #inactive-icon>
                <img src="@/assets/images/0.png" alt="" />
              </template>
              <h3>已下单</h3>
              <p>商品已经下单</p>
              <p>2022-4-18 12:52:54</p>
            </van-step>
          </van-steps>
        </div>
      </div>
    </div>
    <none v-else />
  </div>
</template>
<script>
//A45C9266C090436FA9319ADBF480E801
import none from "@/components/none.vue";
export default {
  name: "logistics-trajectory",
  components: {
    none,
  },
  data() {
    return {
      searchVal: "SF8098483083120",
      goods: "",
    };
  },
  created() {
    if (sessionStorage.getItem("id") === "A45C9266C090436FA9319ADBF480E801") {
      this.show = true;
    }
    // this.goods = sessionStorage.getItem("goods");
  },
  methods: {
    scanCode() {
      // console.log();
      //   console.log("浏览器信息", navigator.userAgent);
      //   this.$router.push({
      //     path: "/scanCodePage",
      //   });
    },
  },
};
</script>

<style lang="scss" scoped>
#logistics_trajectory {
  padding: 12px;
  background: #f5f5f5;
  font-size: 14px;
  .search {
    background-color: #fff;
    border-radius: 6px;
    padding: 16px 4%;
    width: 100%;
    input {
      width: 66%;
      height: 38px;
      border-radius: 4px 0px 0px 4px;
      opacity: 1;
      border: 1px solid #0787f4;
      border-right: none;
      padding: 0 10px;
      color: #222;
    }
    button {
      height: 38px;
    }
    .bt1 {
      width: 18%;
      background: #0787f4;
      border-radius: 0px 4px 4px 0px;
      color: #fff;
      border: none;
    }
    .bt2 {
      margin-left: 4%;
      width: 12%;
      border-radius: 4px;
      border: none;
    }
  }
  .trajectory {
    background-color: #fff;
    margin-top: 12px;
    border-radius: 10px;
    .title {
      position: relative;
      text-align: center;
      border-radius: 10px 10px 0 0;
      font-size: 18px;
      font-weight: bold;
      color: #222222;
      height: 48px;
      line-height: 48px;
      background: linear-gradient(
        180deg,
        #e4f0fe 0%,
        rgba(228, 240, 254, 0.3) 100%
      );
      img {
        position: absolute;
        width: 55px;
        right: 20px;
      }
    }
    .content {
      // border-radius: 0 0 10px 10px;
      .kd {
        display: flex;
        align-items: center;
        padding-left: 12px;
        position: relative;
        margin-top: 15px;
        margin-bottom: 30px;
        a {
          position: absolute;
          left: 46px;
          bottom: -14px;
          font-size: 12px;
          color: #0787f4;
        }
        img {
          width: 28px;
          margin-right: 6px;
        }

        i {
          font-style: unset;
          color: #bbb;
          margin: 0 19px;
        }
        span {
          color: #888;
          .svg-icon {
            margin-right: 6px;
            font-size: 14px;
          }
        }
      }
      ::v-deep .van-steps {
        border-radius: 0 0 10px 10px;

        .van-step--process {
          h3 {
            color: #0787f4 !important;
          }
          p {
            color: #333333 !important;
            span {
              color: #0787f4;
            }
          }
        }
        .van-step {
          padding-left: 16px;
          .van-step__circle-container {
            left: -7px;
            img {
              width: 23px;
              height: 23px;
            }
            .van-step__circle {
              width: 7px;
              height: 7px;
              background: #dadada !important;
            }
          }
          .van-step__line {
            left: -7px;

            background: #e4e5e7 !important;
          }
          .van-step__title {
            h3 {
              font-size: 15px;
              font-weight: bold;
              margin-bottom: 12px;
            }
            p {
              font-size: 12px;
              font-weight: 400;
              color: #888888;
              line-height: 18px;
              margin-bottom: 12px;
            }
            p:last-child {
              margin: 0;
              line-height: 12px;
            }
          }
        }
        .van-step::after {
          border: none;
        }
      }
    }
  }
}
</style>